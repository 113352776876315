<template>
  <orderBilling />
</template>

<script>
// @ is an alias to /src
import orderBilling from "@/components/Orders-Partner-Billing/c-partner-billing.vue";

export default {
  name: "OrdersBilling",
  components: {
    orderBilling,
  },
};
</script>
