<template>
  <div>
    <v-col cols="12" v-if="dataBilling">
      <v-row>
        <v-col cols="4" style="text-align: left">
          <v-col>
            <v-row>
              <b
                >Account Balance:
                <template v-if="dataBilling.accountBalance >= 0"
                  ><span style="color: green; font-size: 25px"
                    >${{ dataBilling.accountBalance }}</span
                  ></template
                >
                <template v-else
                  ><span style="color: red; font-size: 25px"
                    >-${{ dataBilling.accountBalance * -1 }}</span
                  ></template
                ></b
              >
              &nbsp;
              <v-divider vertical v-if="
                  $store.state.gbaUser.indexOf('staff') < 0 &&
                  $store.state.gbaUser.indexOf('partner') < 0
                "></v-divider>
              &nbsp;&nbsp;
              <span
                v-if="
                  $store.state.gbaUser.indexOf('staff') < 0 &&
                  $store.state.gbaUser.indexOf('partner') < 0
                "
              >
                <v-select
                  :items="listPartner"
                  :item-text="(obj) => obj.name"
                  :item-value="(obj) => obj.username"
                  label="Select Partner"
                  dense
                  outlined
                  v-model="parterSelected"
                  hide-details
                  @change="loadData"
                ></v-select>
              </span>
              &nbsp;&nbsp;
              <v-btn color="info" @click="exportData" v-if="!loadingExport">Export</v-btn>
              <v-btn color="info" disabled v-else>Exporting...</v-btn>
            </v-row>
          </v-col>
        </v-col>
        <v-col cols="8" style="text-align: right" v-if="roles.billing_topup">
          <v-btn color="info" @click="dialogTopup = !dialogTopup">Topup</v-btn>
        </v-col>

        <v-col cols="12" v-if="informText != ''">
          <v-alert type="error" v-if="!informType" close-text="Close Alert"
          dense
          text
          dismissible>{{ informText }}</v-alert>
          <v-alert type="success" v-if="informType" close-text="Close Alert"
          dense
          text
          dismissible>{{ informText }}</v-alert>
        </v-col>

        <v-col cols="12" v-if="dataBilling.accountBalance < 0">
          <v-alert color="orange" type="warning">
            You currently have a negative account balance with GBA. Please add
            funds in timely manner to avoid order rejections.
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-card style="padding: 10px; background-color: #e0e0e0">
            <h3>Deposits History</h3>
            <v-data-table
              :headers="headersDeposits"
              :items="dataBilling.depositHistory"
              class="elevation-1"
              :loading="loading"
              :search="search"
            >
              <template v-slot:item.amount="{ item }">
                ${{ item.amount }}
              </template>
              <template v-slot:item.status="{ item }">
                <template v-if="roles.billing_change_transaction_status">
                  <v-select
                    :items="topupStatusList"
                    v-model="item.status"
                    dense
                    hide-details
                    outlined
                    @change="changeStt(item)"
                  ></v-select>
                </template>
                <template v-else>
                  <td v-if="item.status == 'approved'">
                    <b style="color: green">{{ item.status }}</b>
                  </td>
                  <td v-else-if="item.status == 'rejected'">
                    <b style="color: red">{{ item.status }}</b>
                  </td>
                  <td v-else>
                    <b style="color: grey">{{ item.status }}</b>
                  </td>
                </template>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card style="padding: 10px; background-color: #e0e0e0">
            <h3>Bills</h3>
            <v-data-table
              :headers="headersBills"
              :items="dataBilling.bills"
              class="elevation-1"
              :loading="loading"
              :search="search"
            >
              <template v-slot:item.amount="{ item }">
                ${{ item.amount }}
              </template>

              <template v-slot:item.status="{ item }">
                <td v-if="item.status == 'unpaid'">
                  <b style="color: grey">{{ item.status }}</b>
                </td>
                <td v-else>
                  <b style="color: green">{{ item.status }}</b>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <BaseDialog :active.sync="dialogTopup" dialog_name="Create Topup">
      <DialogTopupCreate />
    </BaseDialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import BaseDialog from "../Common/c-base-dialog.vue";
import DialogTopupCreate from "./c-dialog-topup.vue";
export default {
  name: "PartnerBilling",

  components: {
    DialogTopupCreate,
    BaseDialog,
  },

  data: () => ({
    dataBilling: null,

    informText: "",
    informType: null,

    loading: false,
    loadingExport: false,
    search: null,
    dialogTopup: false,
    listPartner: [],
    parterSelected: "",

    topupStatusList: ["approved", "rejected"],

    headersDeposits: [
      { text: "Date", value: "created_date" },
      { text: "Transaction ID", value: "transaction_id" },
      { text: "Note", value: "note" },
      { text: "Amount", value: "amount" },
      { text: "Status", value: "status", width: "15%" },
    ],

    headersBills: [
      { text: "Date", value: "created_date" },
      { text: "Invoice ID", value: "invoice_id" },
      { text: "Amount", value: "amount" },
      { text: "Status", value: "status" },
    ],
  }),

  computed: {
    roles() {
      return this.$store.state.gbaUserPermissions.chinese_suppliers;
    },
  },

  created() {
    this.$root.$refs.orderPartnerBilling = this;
  },

  methods: {
    async changeStt(item) {
      this.showBtn = false;
      await this.$root.$refs.app.checkToken();

      var itemTopup = {
        _id: item._id,
        new_status: item.status,
      };

      await axios
        .post(window.domain + "billing/change-transaction-status", itemTopup)
        .then((response) => {
          if (response.data.error_message != "") {
            this.informText = response.data.error_message;
          } else {
            this.loadData();
          }
        })
        .catch((error) => console.log("Err: ", error));
    },

    async loadData() {
      await this.$root.$refs.app.checkToken();
      var query = {};
      if (this.parterSelected != "") {
        query["account"] = this.parterSelected;
      }

      await axios
        .get(window.domain + "billing", {
          params: query,
        })
        .then((response) => {
          if (response.data.error_message != "") {
            this.informType = false;
            this.informText = response.data.error_message;
          } else {
            this.dataBilling = response.data.data;

            console.log("this.dataBilling: ", this.dataBilling);

            for (var a of this.dataBilling.depositHistory) {
              a["showBtn"] = false;
              a["current_stt"] = JSON.parse(JSON.stringify(a.status));
              a.created_date = moment(a.created_date).format(
                "YYYY-MM-DD HH:MM:SS"
              );
            }

            for (var b of this.dataBilling.bills) {
              b.created_date = moment(b.created_date).format(
                "YYYY-MM-DD HH:MM:SS"
              );
            }
          }
        })
        .catch((error) => console.log("Err: ", error));
    },

    turnOffDialog() {
      this.dialogTopup = false;
      this.loadData();
    },

    async exportData(){
      this.loadingExport = true
      await axios
        .post(window.domain + "billing/export")
        .then((response) => {
          if (response.data.error_message != "") {
            this.informType = false;
            this.informText = response.data.error_message;
            this.loadingExport = false
          } else {
            this.loadingExport = false
            this.informType = true;
            this.informText = "Total exported "+response.data.data.total_exported_rows+" sale(s) to your email."
          }
        })
        .catch((error) => console.log("Err: ", error));
    },
  },

  async mounted() {
    if (this.$store.state.gbaUser.indexOf("staff") > -1) {
      window.location.href = "error";
    } else {
      await this.$root.$refs.app.checkToken();

      await axios
        .get(window.domain + "users?role=Partner")
        .then((response) => {
          if (response.data.error_message != "") {
            this.informText = response.data.data.users;
          } else {
            this.listPartner = response.data.data.users;
            this.listPartner.unshift({
              name: "All",
              username: "",
            });
            console.log("this.listPartner: ", this.listPartner);
          }
        })
        .catch((error) => console.log("Err: ", error));
      await this.loadData();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>