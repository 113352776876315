<template>
  <transition name="dialog">
    <div v-if="active" class="dialog-backdrop">
      <div class="dialog-container" @click.stop>
        <div>
          <b>{{ dialog_name }}</b>
          <!-- <v-btn text style="float:right" title="Close Dialog" @click="handleBackdropClick" v-if="showBtn == true"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
          <v-btn text style="float:right" title="Close Dialog" disabled v-else><v-icon>mdi-close-circle-outline</v-icon></v-btn>  -->
        </div>
        <div
          class="feature_divider"
          style="width: 100%; display: inline-block"
        ></div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    active: { type: Boolean, default: false },
    dialog_name: String,
  },
  created() {
    this.$root.$refs.Dialog = this;
  },
  // data:() => ({
  //   showBtn: true
  // }),
  methods: {
    handleBackdropClick() {
      this.$emit("update:active", false);
      this.showBtn = true;
    },
    // disableExitBtn(){
    //   this.showBtn = false
    //   console.log("showBtn: ",this.showBtn)
    // },
    // enableExitBtn(){
    //   this.showBtn = true
    //   console.log("showBtn: ",this.showBtn)
    // }
  },
};
</script>

<style scoped>
.dialog-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.dialog-container {
  max-width: 80%;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-height: 90%;
  overflow: auto;
  /* margin-top: auto; */
  /* margin-bottom: auto; */
  padding: 15px;
  background-color: #fff;
}

.dialog-enter-active,
.dialog-leave-active {
  transition: opacity 0.2s;
}
.dialog-enter,
.dialog-leave-to {
  opacity: 0;
}

.dialog-enter-active .dialog-container,
.dialog-leave-active .dialog-container {
  transition: transform 0.4s;
}
.dialog-enter .dialog-container,
.dialog-leave-to .dialog-container {
  transform: scale(0.9);
}

.feature_divider {
  margin-bottom: 12px;
  border-color: #aaaaaa;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
</style>