<template>
  <div>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" v-if="informText != ''">
          <v-alert type="error">{{ informText }}</v-alert>
        </v-col>

        <v-col cols="4">
          <v-text-field
            label="Transaction ID"
            dense
            outlined
            v-model="topupData.transaction_id"
            @keyup="validation"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            label="Note"
            dense
            outlined
            v-model="topupData.note"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            label="Amount"
            dense
            outlined
            @keyup="validation"
            v-model="topupData.amount"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-btn color="success" @click="saveData" v-if="showBtn">CREATE</v-btn>
          <v-btn color="success" disabled v-else>CREATE</v-btn>
          &nbsp;
          <v-btn color="orange" dark @click="cancelData">CANCEL</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DialogTopup",

  data: () => ({
    topupData: {
      transaction_id: "",
      note: "",
      amount: 0,
    },
    informText: "",
    showBtn: true,
  }),

  methods: {
    async saveData() {
      this.showBtn = false;
      await this.$root.$refs.app.checkToken();

      await axios
        .post(window.domain + "billing", this.topupData)
        .then((response) => {
          if (response.data.error_message != "") {
            this.showBtn = true;
            this.informText = response.data.error_message;
          } else {
            this.cancelData();
          }
        })
        .catch((error) => console.log("Err: ", error));
    },

    validation() {
      if (this.topupData.transaction_id != "" && this.topupData.amount > 0) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
      console.log("showBtn: ", this.showBtn);
    },

    cancelData() {
      this.$root.$refs.orderPartnerBilling.turnOffDialog();
    },
  },

  mounted() {
    this.validation();
  },
};
</script>

<style lang="scss" scoped>
</style>